import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { product_item_availability } from 'commons/constants';
import getBreadcrumbsForPage from 'commons/getBreadcrumbsForPage';
import useProductOrderETA from '../useProductOrderETA';
import { findAvailabilityAndVisibility } from '../utils';

/**
 *  Used in the PDP to determine if product is out of stock
 *  or discontinued based on stock response (both EFG internal and FFG).
 *  This controls the notification displayed in the PDP.
 *
 *  Returns object of shape
 *  {
 *    loading,
 *    error
 *    data : {
 *     isOutOfStock,
 *     showNotification,
 *     isEFGInternal,
 *     isDiscontinued
 *    }
 *  }
 */

const usePDPGetStockAvailability = ({ basicDetails, productOptions }) => {
  const enableETAFetch = true;

  const {
    loading: etaLoading,
    data: { orderETA, isETAVariant },
    actions: { fetchProductOrderETA },
  } = useProductOrderETA();

  const {
    loading: basicDetailsLoading,
    error: basicDetailsError,
    data: basicDetailsData,
  } = basicDetails;

  const {
    loading: optionsLoading,
    data: optionsData,
    error: optionsError,
  } = productOptions;

  const params = useParams();

  const [showNotification, updateShowNotification] = useState({
    show: false,
    productId: null,
  });
  const [isOutOfStock, updateisOutOfStock] = useState(false);
  const [isDiscontinued, setIsDiscontinued] = useState(false);
  const [isMarkedOOSOrDiscontinued, setIsMarkedOOSOrDiscontinued] = useState(
    false
  );
  const [availabilitySet, updateAvailabilitySet] = useState({
    forProductId: null,
    status: false,
  });
  const [discontinuedCategory, updateDiscontinuedCategory] = useState(null);

  const { stock = null } = optionsData;

  useEffect(() => {
    if (
      !basicDetailsData ||
      !stock ||
      stock?.loading ||
      typeof stock?.inStockOptionIds === 'undefined' ||
      basicDetailsData?.id !== stock?.idForStock ||
      (availabilitySet.status &&
        availabilitySet.forProductId === basicDetailsData?.id)
    ) {
      return;
    }
    const {
      inStockOptionIds = [],
      selectedOptionsStockQty = 0,
      idForStock,
    } = stock;
    let isMarkedOOS = false;
    const isEFGInternal =
      basicDetailsData.efg_flag && !basicDetailsData.is_external;
    const isEFGExternal =
      basicDetailsData.efg_flag && basicDetailsData.is_external;
    const { availability } = findAvailabilityAndVisibility(
      basicDetailsData.attributes
    );
    if (
      `${availability}` === `${product_item_availability.out_of_stock}` ||
      `${availability}` === `${product_item_availability.disabled}`
    ) {
      isMarkedOOS = true;
    }
    const isDiscontinuedFlag = !!basicDetailsData?.discontinued;
    if (isMarkedOOS && enableETAFetch) {
      fetchProductOrderETA(basicDetailsData.id);
    }

    // Use flags for EFG External
    if (isEFGExternal && (isMarkedOOS || isDiscontinuedFlag)) {
      if (isMarkedOOS) {
        updateShowNotification({
          productId: basicDetailsData?.id,
          show: true,
        });
        updateisOutOfStock(true);
      }
      if (isDiscontinuedFlag) {
        setIsDiscontinued(true);
      }
      updateAvailabilitySet({
        forProductId: basicDetailsData?.id,
        status: true,
      });
      return;
    }
    if (!isMarkedOOS && !isDiscontinuedFlag) {
      // Always check stock for EFG Internal
      if (isEFGInternal) {
        if (enableETAFetch) {
          fetchProductOrderETA(basicDetailsData.id);
        }
        if (showNotification.productId !== idForStock) {
          if (!inStockOptionIds.length && !selectedOptionsStockQty) {
            updateShowNotification({
              productId: basicDetailsData?.id,
              show: true,
            });
            updateisOutOfStock(true);
            updateAvailabilitySet({
              forProductId: basicDetailsData?.id,
              status: true,
            });
            return;
          }
          if (
            inStockOptionIds.length ||
            (selectedOptionsStockQty && showNotification.show)
          ) {
            updateShowNotification({
              productId: basicDetailsData?.id,
              show: false,
            });
            updateisOutOfStock(false);
            updateAvailabilitySet({
              forProductId: basicDetailsData?.id,
              status: true,
            });
          }
        }
      }
      if (
        showNotification?.show &&
        showNotification?.productId !== basicDetailsData?.id
      ) {
        // Update for FFG not marked as OOS or discontinued
        updateShowNotification({
          productId: basicDetailsData?.id,
          show: false,
        });
        updateisOutOfStock(false);
        setIsDiscontinued(false);
        updateAvailabilitySet({
          forProductId: basicDetailsData?.id,
          status: true,
        });
      }
      return;
    }

    if (!inStockOptionIds.length && !selectedOptionsStockQty) {
      if (isMarkedOOS) {
        updateShowNotification({
          productId: basicDetailsData?.id,
          show: true,
        });
        updateisOutOfStock(true);
      }
      if (isDiscontinuedFlag) {
        setIsDiscontinued(true);
      }
      updateAvailabilitySet({
        forProductId: basicDetailsData?.id,
        status: true,
      });
      return;
    }

    if (!!inStockOptionIds.length || !!selectedOptionsStockQty) {
      if (isOutOfStock) {
        updateShowNotification({
          productId: basicDetailsData?.id,
          show: false,
        });
        updateisOutOfStock(false);
      }
      if (isDiscontinued) {
        setIsDiscontinued(false);
      }
      updateAvailabilitySet({
        forProductId: basicDetailsData?.id,
        status: true,
      });
    }
    // TODO: To prevent infinite loops, we need to refactor this code to
    // consolidate the data used into a single object, which would allow us
    // to add all dependencies and use a functional useState update.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicDetailsData, stock]);

  useEffect(() => {
    if (!basicDetailsData?.id) {
      return;
    }
    let isMarkedOOS = false;
    const { availability } = findAvailabilityAndVisibility(
      basicDetailsData.attributes
    );
    if (
      `${availability}` === `${product_item_availability.out_of_stock}` ||
      `${availability}` === `${product_item_availability.disabled}`
    ) {
      isMarkedOOS = true;
    }
    const isDiscontinuedFlag = !!basicDetailsData?.discontinued;

    setIsMarkedOOSOrDiscontinued(prevIsMarkedOOSOrDiscontinued => {
      if (
        (isMarkedOOS || isDiscontinuedFlag) &&
        !prevIsMarkedOOSOrDiscontinued
      ) {
        return true;
      }

      if (prevIsMarkedOOSOrDiscontinued) {
        return false;
      }

      return prevIsMarkedOOSOrDiscontinued;
    });
  }, [basicDetailsData]);

  useEffect(() => {
    if (
      etaLoading ||
      !orderETA?.forProductId ||
      !isOutOfStock ||
      !orderETA?.eta ||
      (!!showNotification.eta && showNotification.eta === orderETA.eta)
    ) {
      return;
    }
    updateShowNotification({
      productId: basicDetailsData?.id,
      show: true,
      eta: orderETA.eta,
    });
  }, [etaLoading, orderETA, isOutOfStock, showNotification, basicDetailsData]);

  useEffect(() => {
    if (!basicDetailsData?.discontinued) {
      setIsDiscontinued(false);
    }
    if (
      !isDiscontinued ||
      !basicDetailsData?.id ||
      (!!discontinuedCategory?.forId &&
        discontinuedCategory?.forId === basicDetailsData?.id)
    ) {
      return;
    }
    const categoryName = params?.category;
    const allBreadCrumbs = getBreadcrumbsForPage('pdp');
    const productBreadCrumbs =
      allBreadCrumbs?.[categoryName] &&
      allBreadCrumbs?.[allBreadCrumbs[categoryName]];
    const finalCategory = productBreadCrumbs?.length
      ? productBreadCrumbs.pop()
      : null;
    if (finalCategory) {
      const updatedDiscontinuedMsg = {
        category: finalCategory.label,
        link: finalCategory.link,
        forId: basicDetailsData?.id,
      };
      updateDiscontinuedCategory(updatedDiscontinuedMsg);
    }
  }, [isDiscontinued, basicDetailsData, params, discontinuedCategory]);

  useEffect(() => {
    if (
      !basicDetailsData?.id ||
      !availabilitySet.status ||
      availabilitySet.forProductId === basicDetailsData?.id
    ) {
      return;
    }
    updateAvailabilitySet({
      forProductId: null,
      status: false,
    });
  }, [basicDetailsData, availabilitySet]);

  return {
    loading: basicDetailsLoading || optionsLoading,
    error: basicDetailsError || optionsError,
    data: {
      isOutOfStock,
      showNotification,
      isDiscontinued,
      isEFGInternal: !!(
        basicDetailsData?.efg_flag && !basicDetailsData.is_external
      ),
      isMarkedOOSOrDiscontinued,
      isETALoading: etaLoading,
      isETAVariant,
      isStockLoading: stock?.loading,
      discontinuedCategoryMsg: discontinuedCategory,
    },
  };
};

export default usePDPGetStockAvailability;
