import DOMPurify from 'isomorphic-dompurify';

/* Accepts the following arguments
 * content : The string value to sanitize
 * options : An object to set options within sanitize, please follow isomorphic-DOMPurify documentation for property names
 */
const DOMPurified = () => {
  const sanitize = (content, options = {}) =>
    DOMPurify.sanitize(content, { ...options });

  return {
    sanitize,
  };
};

export default DOMPurified();
