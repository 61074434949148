import { useState, useCallback, useMemo } from 'react';

import useSuspenseQuery from 'commons/useSuspenseQuery';
import { GET_PRODUCTS_ORDER_ETA } from 'mocks/queries';

const useProductOrderETA = () => {
  const [productId, setProductId] = useState(null);

  const { loading, data, refetch: getProductOrderETA } = useSuspenseQuery(
    GET_PRODUCTS_ORDER_ETA,
    {
      variables: {
        productIds: [productId],
      },
      skip: !productId,
      ssr: true,
    }
  );

  const fetchProductOrderETA = useCallback(
    prodId => {
      if (!prodId || prodId === productId) {
        return;
      }
      setProductId(prodId);
      getProductOrderETA({
        productIds: [prodId],
      });
    },
    [getProductOrderETA, productId]
  );

  const orderETA = useMemo(() => {
    if (!productId || loading || !data?.getOrderETAForProductIds?.[productId]) {
      return {
        forProductId: null,
        eta: '',
      };
    }
    const etaDate = new Date(data.getOrderETAForProductIds[productId]);
    const formatter = new Intl.DateTimeFormat('en-us', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
    return {
      forProductId: productId,
      eta: formatter.format(etaDate),
    };
  }, [loading, data, productId]);

  return {
    loading,
    data: {
      orderETA,
    },
    actions: {
      fetchProductOrderETA,
    },
  };
};

export default useProductOrderETA;
