import { PDP_READYTOSHIP_TAB_ID } from 'commons/constants';

// Get all options for product in the selected customizer tab
const usePDPOptionsForCustomizerTab = ({
  productOptions,
  selectedCustomizerTab,
  stock,
}) => {
  if (
    selectedCustomizerTab === PDP_READYTOSHIP_TAB_ID &&
    stock?.stockOptions?.length
  ) {
    return stock?.stockOptions;
  }

  return productOptions || null;
};

export default usePDPOptionsForCustomizerTab;
