import { useCallback } from 'react';

import useSuspenseQuery from 'commons/useQuery';
import useIsBrowser from 'hooks/useIsBrowser';
import { GET_LOCATION_FROM_ZIPCODE } from 'mocks/queries';
import getCleanLocation from './getCleanLocation';
import getLocationErrorMessage from './getLocationErrorMessage';

const DEFAULT_VALUE = {
  location: undefined,
  errorMessage: undefined,
  isLoading: false,
  refetch: () => {},
};

const useUserLocationFromZipcode = zipcode => {
  const isBrowser = useIsBrowser();

  const { loading, data, error, refetch: refetchQuery } = useSuspenseQuery(
    GET_LOCATION_FROM_ZIPCODE,
    {
      variables: { zipcode },
      skip: !zipcode,
    }
  );

  // We provide a custom async refetch function to return the location for a
  // specified zip code with a formatted error message
  const refetch = useCallback(
    async updatedZipCode => {
      const { data: updatedData, error: updatedError } = await refetchQuery({
        zipcode: updatedZipCode,
      });

      const updatedLocationResponse = updatedData?.getLocationFromZip;
      const updatedLocation = getCleanLocation(updatedLocationResponse);
      const updatedResponseStatus = updatedLocationResponse?.status;

      const updatedErrorMessage = getLocationErrorMessage({
        error: updatedError,
        isLoading: false,
        responseStatus: updatedResponseStatus,
        zipcode: updatedZipCode,
      });

      return {
        location: updatedLocation,
        errorMessage: updatedErrorMessage,
      };
    },
    [refetchQuery]
  );

  if (!isBrowser) {
    return { ...DEFAULT_VALUE, refetch };
  }

  const isLoading = loading && !data;
  const locationResponse = data?.getLocationFromZip;
  const responseStatus = locationResponse?.status;

  const errorMessage = getLocationErrorMessage({
    error,
    isLoading,
    responseStatus,
    zipcode,
  });

  const location = getCleanLocation(locationResponse);

  return {
    location,
    errorMessage,
    isLoading,
    refetch,
  };
};

export default useUserLocationFromZipcode;
