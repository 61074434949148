import React from 'react';
import PropTypes from 'prop-types'

const IconCloseEllipse = props => {
  const color = props.color || '#424242';
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 8.78501L5.40234 11.3827L4.9408 10.9211L7.53847 8.32348L4.94081 5.72582L5.40234 5.26429L8 7.86194L10.5977 5.26428L11.0592 5.72582L8.46153 8.32348L11.0592 10.9211L10.5977 11.3827L8 8.78501Z"
        fill={color}
      />
      <circle cx="8" cy="8" r="7.5" stroke={color} fill="none" />
    </svg>
  );
};

IconCloseEllipse.propTypes = {
  color: PropTypes.string
}

export default IconCloseEllipse;
