import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { withRouter } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import { RemoveScroll } from 'react-remove-scroll';

import useOnEsc from 'hooks/useOnEsc';
import useTrapFocus from 'hooks/useTrapFocus';
import useRandomId from 'hooks/useRandomId';
import useFocusOnOpen from 'hooks/useFocusOnOpen';
import VisuallyHidden from 'components/consumer/VisuallyHidden';
import getOrCreateElementById from 'commons/getOrCreateElementById';
import withBrowserOnly from 'components/hoc/withBrowserOnly';
import useScreen from 'hooks/useScreen';
import MobileModalCloseIcon from './MobileModalCloseIcon';
import MobileModalContentUnderHeader from './MobileModalContentUnderHeader';

const mobileModalRootElement = getOrCreateElementById('MobileModal-root');

const MobileModal = ({
  backgroundColor = 'white',
  children,
  className = '',
  closeIcon = 'close',
  contentNoPadding = false,
  contentUnderHeader = null,
  enableHashHistory = true,
  headerCenter,
  headerNoPointerEvents = false,
  headerRight,
  headerRightClassName = '',
  hideHeader = false,
  history,
  isFullHeight = false,
  isMaterialSwatch = false,
  isOpen = false,
  location = {},
  lockScrollWhenOpen = true,
  modalPath = 'modal',
  noPadding = false,
  noStickyFooterPadding = false,
  onClose,
  stickyFooter,
  title,
  variant,
}) => {
  const { isMobile } = useScreen();

  const modalRef = useFocusOnOpen(isOpen);
  const modalTitleId = useRandomId('MobileModal-title');
  useTrapFocus(isOpen, modalRef);

  const [isHistoryPushed, setIsHistoryPushed] = useState(false);
  const locationStateString = JSON.stringify(location.state);

  useEffect(() => {
    if (
      isMobile &&
      enableHashHistory &&
      history &&
      !isHistoryPushed &&
      isOpen &&
      history?.location?.hash !== modalPath
    ) {
      const locationState = JSON.parse(locationStateString || '{}');
      history.push(
        history?.location?.search
          ? `${history.location.search}#${modalPath}`
          : `#${modalPath}`,
        locationState
      );
      setIsHistoryPushed(true);

      history.listen(() => {
        if (history.action === 'POP') {
          onClose();
        }
      });
    }
  }, [
    isMobile,
    isOpen,
    enableHashHistory,
    history,
    isHistoryPushed,
    modalPath,
    onClose,
    locationStateString,
  ]);

  useEffect(() => {
    if (!isOpen) {
      setIsHistoryPushed(false);
    }
  }, [isOpen]);

  const { height } = useWindowSize(null, null);
  const [hasScrolledDown, setHasScrolledDown] = useState(false);

  const onCloseClick = () => {
    if (isMobile && enableHashHistory && history && isHistoryPushed) {
      history.goBack();
    }

    onClose();
  };

  useOnEsc(isOpen, onCloseClick);

  if (!isOpen) {
    return null;
  }

  return createPortal(
    <RemoveScroll removeScrollBar={false} enabled={lockScrollWhenOpen}>
      <div
        className={classNames(
          `lg:hidden flex flex-col fixed z-[2000] h-screen left-0 top-0 w-screen ${className}`,
          {
            'bg-white': isFullHeight,
          }
        )}
        style={height ? { height } : undefined}
        ref={modalRef}
        role="dialog"
        aria-labelledby={modalTitleId}
      >
        <VisuallyHidden>Modal dialog content starts</VisuallyHidden>

        {!isFullHeight && (
          <div
            aria-hidden
            className={classNames(
              'bg-[rgba(0,0,0,.3)] h-screen left-0 absolute top-0 w-screen',
              {
                hidden: isFullHeight,
              }
            )}
            onClick={onCloseClick}
            tabIndex="-1"
          />
        )}

        {!hideHeader && (
          <div
            className={classNames('flex h-14 p-4 items-center', {
              'pointer-events-none invisible': headerNoPointerEvents,
              'pt-4 pr-4 pb-4 pl-10': isMaterialSwatch,
              '!z-[4003]': variant === 'GridWithSidebar',
              'bg-white': backgroundColor !== 'green' && !isMaterialSwatch,
              'bg-brand-dark': backgroundColor === 'green',
              '!bg-transparent': variant === 'ModularAddProducts',
              relative: !contentUnderHeader,
              '!bg-transparent absolute w-screen z-[11]': contentUnderHeader,
              'after:[content:""] after:[background-image:linear-gradient(0deg,hsla(0,0%,93.3%,0)_,rgba(0,0,0,.3))] after:top-0 after:left-0 after:bottom-0 after:right-0 after:absolute after:[transition:opacity_.2s_ease-in-out] after:z-[-1]': contentUnderHeader,
              'after:opacity-100': contentUnderHeader && !hasScrolledDown,
              '!bg-white after:opacity-0':
                contentUnderHeader && hasScrolledDown && !isMaterialSwatch,
              'flex-row-reverse': closeIcon === 'arrow',
            })}
          >
            <div
              className={classNames(`z-[1] ${headerRightClassName}`, {
                '[transition:opacity_.2s_ease-in-out]': contentUnderHeader,
                'opacity-0 pointer-events-none':
                  contentUnderHeader && !hasScrolledDown,
                'opacity-100 pointer-events-auto':
                  contentUnderHeader && hasScrolledDown,
              })}
            >
              {headerRight}
            </div>

            <div
              className={classNames('h-full text-center flex-1 z-0', {
                'text-base font-bold':
                  variant === 'PDPFullScreenViewMobileSidebar' ||
                  variant === 'PDPFullScreenViewUI',
                'pointer-events-none cursor-default [transition:opacity_.2s_ease-in-out]': contentUnderHeader,
                'opacity-0': contentUnderHeader && !hasScrolledDown,
                'opacity-100': contentUnderHeader && hasScrolledDown,
              })}
            >
              {headerCenter}
            </div>

            <span
              className="items-center cursor-pointer flex h-14 w-14 justify-end z-10"
              role="button"
              tabIndex="0"
              onClick={onCloseClick}
            >
              <MobileModalCloseIcon
                icon={closeIcon}
                color={
                  closeIcon === 'close-white' ||
                  (contentUnderHeader && !hasScrolledDown)
                    ? 'white'
                    : 'content'
                }
                variant={variant}
              />
            </span>
          </div>
        )}

        {isMaterialSwatch && !hasScrolledDown && (
          <div
            className={classNames('justify-end flex h-14 pt-4 pr-4 pb-4 pl-6', {
              '!z-[4003]': variant === 'GridWithSidebar',
              '!bg-transparent': variant === 'ModularAddProducts',
              'bg-white': backgroundColor !== 'green' && !isMaterialSwatch,
              'bg-brand-dark': backgroundColor === 'green',
              relative: !contentUnderHeader,
              'bg-transparent absolute w-screen z-[11]': contentUnderHeader,
              'after:[content:""] after:[background-image:linear-gradient(0deg,hsla(0,0%,93.3%,0)_,rgba(0,0,0,.3))] after:top-0 after:left-0 after:bottom-0 after:right-0 after:absolute after:[transition:opacity_.2s_ease-in-out] after:z-[-1]': contentUnderHeader,
              'after:opacity-100': contentUnderHeader && !hasScrolledDown,
              'bg-white after:opacity-0':
                contentUnderHeader && hasScrolledDown && !isMaterialSwatch,
            })}
            onClick={onCloseClick}
            role="presentation"
          >
            <div
              className={classNames(`z-[1] ${headerRightClassName}`, {
                '[transition:opacity_.2s_ease-in-out]': contentUnderHeader,
                'opacity-0 pointer-events-none':
                  contentUnderHeader && !hasScrolledDown,
                'opacity-100 pointer-events-auto':
                  contentUnderHeader && hasScrolledDown,
              })}
            >
              {headerRight}
            </div>
          </div>
        )}

        <div
          className={classNames(
            'relative rounded-bl-lg rounded-br-lg overflow-auto pt-0 pb-[42px]',
            {
              '!p-0':
                contentNoPadding ||
                variant === 'PDPFullScreenViewMobileSidebar',
              '!bg-transparent [&_img]:w-4/5 [&_img]:m-[10%]':
                variant === 'ModularAddProducts',
              'bg-white': backgroundColor === 'white',
              'bg-brand-dark': backgroundColor === 'green',
              'rounded-bl-none rounded-br-none flex-1': isFullHeight,
              'px-4': !noPadding,
              'max-h-screen': contentUnderHeader,
            }
          )}
          id="MobileModal-content"
        >
          {!!contentUnderHeader && (
            <div
              className={classNames({
                'ml-[-.5%] mr-0': !noPadding,
              })}
            >
              <MobileModalContentUnderHeader
                onIntersectionChange={isIntersecting => {
                  // Ignore scroll down on material swatches page
                  if (!isMaterialSwatch) {
                    setHasScrolledDown(!isIntersecting);
                  }
                }}
              >
                {contentUnderHeader}
              </MobileModalContentUnderHeader>
            </div>
          )}
          {title && (
            <h2
              className="text-xl font-bold text-gray m-0 pt-[5px] px-0 pb-2"
              id={modalTitleId}
            >
              {title}
            </h2>
          )}
          {children}
        </div>

        <div
          className={classNames({
            hidden: !stickyFooter,
            'bg-white [border-top:1px_solid_#f1f1f1]': !!stickyFooter,
            'supports-[padding:max(0px)]:pb-[max(11px,_env(safe-area-inset-bottom))]': !!stickyFooter,
            'px-4 py-[11px] pb-0': !!stickyFooter && !noStickyFooterPadding,
          })}
        >
          {stickyFooter}
        </div>

        <VisuallyHidden>Modal dialog content ends</VisuallyHidden>
      </div>
    </RemoveScroll>,
    mobileModalRootElement
  );
};

MobileModal.propTypes = {
  backgroundColor: PropTypes.oneOf(['white', 'green']),
  children: PropTypes.node,
  className: PropTypes.string,
  closeIcon: PropTypes.oneOf(['close', 'arrow']),
  contentNoPadding: PropTypes.bool,
  contentUnderHeader: PropTypes.node,
  enableHashHistory: PropTypes.bool,
  headerCenter: PropTypes.node,
  headerNoPointerEvents: PropTypes.bool,
  headerRight: PropTypes.node,
  headerRightClassName: PropTypes.string,
  hideHeader: PropTypes.bool,
  history: PropTypes.object,
  isFullHeight: PropTypes.bool,
  isMaterialSwatch: PropTypes.bool,
  isOpen: PropTypes.bool,
  location: PropTypes.shape({}),
  lockScrollWhenOpen: PropTypes.bool,
  modalPath: PropTypes.string,
  noPadding: PropTypes.bool,
  noStickyFooterPadding: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  stickyFooter: PropTypes.node,
  title: PropTypes.string,
  variant: PropTypes.oneOf([
    'GridWithSidebar',
    'ModularAddProducts',
    'PDPFullScreenViewMobileSidebar',
    'PDPFullScreenViewUI',
  ]),
};

export default withBrowserOnly(withRouter(MobileModal));
